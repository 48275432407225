@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  overflow: auto !important;
  padding-right: 0 !important;
}
.navbar{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99999999;
}
/* PizzaList.css */

.add-button {
  background-color: #ffcc00;
  color: white;
  padding: 15px;
  border: 3px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
width: 70px;
line-height: 20px;
font-weight: 900;
height: 70px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #e6b800;
}

.card {
  position: relative;
  overflow: hidden;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
}

.card-body {
  position: relative;
  padding: 16px;
  background-color: white;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.card-text {
  margin-bottom: 8px;
}

body {
  font-family: 'Arial', sans-serif;
}

.container {
  max-width: 600px;
  margin: 0 auto;
}



.fixed {
  position: fixed;
  bottom: 16px;
  /* right: 16px; */
}

.bg-orange-500 {
  background-color: #f97316;
}

/* styles.css */

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  padding: 20px;
}

.container1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}



input[type="file"] {
  border: none;
}




/* Product list styles */

.product-item {
  border-bottom: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .product-item {
    flex-direction: row;
    align-items: center;
  }

  .product-item img {
    width: 200px;
    margin-right: 20px;
  }

  .product-item div {
    flex: 1;
  }
}


/* frontend/src/ProductList.css */

.product-list-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
}

.product-list-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  color: #333;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.product-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.product-card h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #007bff;
}

.product-card p {
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

/* src/components/ProductTable.css */

.table-container {
  width: 90%;
  margin: 20px auto;
  overflow-x: auto;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  text-align: left;
}

.product-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.product-table th,
.product-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.product-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.product-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.product-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.product-image {
  width: 50px;
  height: auto;
}

/* src/components/ProductCards.css */
/* 
.product-list-container {
  width: 90%;
  margin: 20px auto;
}

.product-list-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card .relative {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100%;
  padding-bottom: 75%; 
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: #f3f3f3;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-container {
  padding: 20px;
  text-align: center;
}

.details-container .flex {
  justify-content: space-between;
}

.details-container p {
  margin: 10px 0;
}

.star {
  color: gold;
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }

  .details-container {
    margin-top: 10px;
  }
} */

/* src/components/Dashboard.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.dashboard-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.dashboard-card {
  background-color: #007bff;
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.dashboard-card:hover {
  transform: translateY(-5px);
}

.dashboard-card h2 {
  margin-bottom: 10px;
}

.dashboard-card p {
  margin: 0;
  color: #f0f0f0;
}


.menu-panel {
  transform: translateY(100%);
}

.menu-panel.open {
  transform: translateY(0);
}

.icon.open {
  background-color: #ff6600; /* Example color when menu is open */
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem; /* Adjust as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust as needed */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.05);
}

.image-container img {
  width: 100%;
  height: 12rem; /* Adjust as needed */
  object-fit: cover;
  transition: filter 0.3s ease;
}

.image-container:hover img {
  filter: blur(4px); /* Adjust blur level as needed */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay p {
  font-size: 1rem; /* Adjust as needed */
  font-weight: 600;
  color: white; /* Change to black if needed */
  text-align: center;
  text-transform: uppercase;
}

.allmenu{
  text-transform: uppercase;
  padding-top: 30px;
  font-size: 1.4rem;
}
/* Menu.css */

/* Hide scrollbar but allow scrolling */
.flex-1 {
  overflow-y: scroll;
}

/* For webkit-based browsers (Chrome, Safari) */
.flex-1::-webkit-scrollbar {
  display: none;
}

/* Optional: for additional cross-browser support */
.flex-1 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Adjust footer bar */
.fixed {
  overflow: hidden; /* Ensures no extra scrollbar appears */
}

.modal-dialog {
  max-height: 80vh; /* Adjust height as needed */
}

.modal-body {
  overflow-y: auto;
}
/* Add this to your CSS file to ensure the modal slides up smoothly */



/* Animation Keyframes */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
   
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

/* Animation Classes */
.slide-up {
  animation: slideUp .5s linear;
  width: 100%;
}

.slide-down {
  animation: slideDown 0.5s ease-in forwards;
}

/* Modal Top Positioning */
.modal-top {
  position: fixed;
  top: 0;
  left: 0;  /* Adjusted to start from the left edge */
  width: 100%;  /* Full width of the screen */
  /* transform: translateX(0);  Centered on the screen horizontally */
  max-width: none;  /* Remove any max-width restriction */
  border-radius: 0 0 15px 15px;  /* Rounded bottom corners */
  z-index: 1050;  /* Ensure it appears on top of other elements */
}
