/* Ensure the menu button is properly positioned */
.menu-button-container {
    position: fixed;
    bottom: 70px; /* Adjust as needed */
    right: 60px; /* Adjust as needed */
    z-index: 1000;
  }
  
  /* Style for the menu button */
  .menu-btn {
    background-color: #007bff;
    border: 2px solid white;
    border-radius: 50%;
    padding: 15px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  /* Overlay for the menu */
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: flex-end; /* Align to the bottom */
    justify-content: center;
    transition: opacity 0.3s ease;
    opacity: 0;
    
    pointer-events: none;
    z-index: 10000000000;

  }
  
  .menu-overlay.show {
    opacity: 1;
    pointer-events: auto;
    z-index: 10000000000;
  }
  
  /* Menu container styling and transitions */
  .menu-container {
    background-color: #ffffff;
    width: 100%;
    /* max-width: 500px; */
    /* padding: 20px; */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* transform: translateY(100%); */
    /* transition: transform 0.3s ease; */
  }
  
  /* .menu-container.slide-up {
    transform: translateY(0);
  } */
  
  .menu-container.slide-down {
    /* transform: translateY(100%); */
  }
  
  /* Responsive adjustments */
  @media (max-width: 767.98px) {
    .menu-container {
      padding: 10px;
    }
  }
  
  /* Header styling */
.menu-header {
    background-color: #f8f9fa; /* Light background for header */
    padding: 5px;
    border-bottom: 1px solid #dee2e6; /* Subtle border at the bottom */
  }
  
  .menu-title {
    font-size:1rem; /* Font size for the title */
    font-weight: 700; /* Bold text */
    text-transform: uppercase; /* Make text uppercase */
    color: #343a40; /* Dark text color */
  }
  
  /* Button styling */
  .cancel-btn {
    background-color: #6c757d; /* Secondary button color */
    border: none;
    color: white;
  }
  
  /* Card styling */
  .category-card {
    background-color: #ffffff; /* Card background color */
    border: 1px solid #ced4da; /* Light border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .category-card:hover {
    background-color: #f8f9fa; /* Light background on hover */
  }
  
  /* Text styling */
  .card-title {
    font-size: .8rem; /* Font size for category title */
    font-weight: 700; /* Semi-bold text */
    color: #343a40; /* Dark text color */
    text-transform: uppercase; /* Make text uppercase */
    letter-spacing: 1.1px;
  }

  .card {
    overflow: hidden;
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    /* padding: 20px; */
    /* border-top: 2px solid red; */
    /* margin-top: 10px; */
    border: none;
    /* border-bottom: 1px solid black; */
  }
 .product-use{
  margin-top: 70px;
  /* border-bottom: 1px solid rgb(155, 154, 154); */
  padding: 10px;
  text-transform: uppercase;
 }
 .product-box{
  padding: 10px;
  border-top: .1px solid rgba(194, 194, 194, 0.362);
  border-bottom: .1px solid rgba(194, 194, 194, 0.362);
 }
.my-products{
  background-color: white;
  box-shadow: 0px 0px 2px .1px black;
  padding: 5px;
  border-radius: 2px;
}
.products-cards{
  /* background-color: red; */
  height: fit-content;
}
.myimage-container{
width:30%;
}
.myimage-container img{
  width: 100%;
  object-fit: fill;
  object-position: center;

}
.button-container{
  position: absolute;
  background-color: gray;
  left: 1;
  bottom: -20px;
  z-index: 9999999;
  width: fit-content;
  border-radius: 5px;
  
}
.Product-container{
  width: 80%;
}
  .card-img-top {
    border-bottom: 1px solid #ddd;
  }
  
  .card-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .card-text {
    margin-bottom: 0.3rem;
    font-size: .7rem;
  }
  
  .text{
    color: #6c757d;
  }
  
  .text-danger {
    color: #dc3545;
  }
  
  .btn {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .bg-red-500 {
    background-color: #dc3545;
  }
  
  .bg-green-500 {
    background-color: #28a745;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .mb-3 {
    margin-bottom: 1rem;
  }
  /* Ensure the "Add" button container has proper styling */
.relative .add-button-container {
  background-color: #ffffff;
  padding: 10px;
  border-top: 1px solid #ddd;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

/* Ensure text is properly styled */
.text-gray-600 {
  color: #6c757d;
}

.text-gray-700 {
  color: #495057;
}

.total-payable-section {
  margin-top: 20px;
  background-color: #f8f9fa; /* Light background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.total-payable-label {
  font-weight: bold;
  color: #333;
}

.total-payable-amount {
  font-weight: bold;
  color: #d9534f; /* Red or orange for emphasis */
  font-size: 1.5rem;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Ensure it matches the card background */
  padding: 10px 20px;
  border-top: 1px solid #ddd; /* Optional: border to separate footer */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.me-2 {
  margin-left: auto; /* Pushes the "Add Address & Pay" button to the right */
}
