/* Custom CSS for CheckoutPage */

.container-fluid {
    width: 100%;
    background-color: rgb(245, 241, 241)
    ;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-button {
    border-radius: 10px;
    font-size: 1.25rem;
  }
  
  .quantity-button-decrease {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .quantity-button-increase {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .quantity-value {
    margin: 0 10px;
    font-size: 1.25rem;
  }
  
  /* CheckoutPage.css */

.payment-method-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: #333;
}

.payment-options {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.payment-option {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  transition: background-color 0.3s ease;
}

.payment-option:hover {
  background-color: #f7f7f7;
}

.payment-option input[type='radio'] {
  margin-right: 0.75rem;
  accent-color: #ff5722; /* Customize the color as needed */
}
