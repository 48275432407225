.shop-card {
  transition: transform 0.3s ease;
}

.shop-card:hover {
  transform: translateY(-5px);
}

.shop-image {
  object-fit: cover;
  height: 200px;
}

.shop-status.open {
  color: green;
  font-weight: bold;
}

.shop-status.closed {
  color: red;
  font-weight: bold;
}

.shop-countdown, .shop-next-opening {
  font-size: 0.9rem;
  color: #555;
}

.card-body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h5 {
  font-family: 'Roboto', sans-serif;
}
